.groupbutton-scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.title_left {
    width: "50%";
    float: "left";
}

.title_right {
    width: "50%";
    float: "left";
    text-align: "end";
}
.hori_select {
  margin-top: 10px;
  display: -webkit-box;
  overflow-x: scroll;
  gap: 10px;
}
.hori_select .hori_items {
  width:fit-content;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 5px;
}
.hori_select .hori_items.active {
  background-color: var(--primary-color);
  color: #fff;
}