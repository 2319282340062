
.download_mainsppsec {background: url(/css/images/mainbg_app.png) center center no-repeat; min-height: 645px; width: 100%; display: flex; justify-content: center;
    align-items: center; position: absolute; height: 100%; background-size: cover;}
.inner_downloadsec {text-align: center;}
.down_contsec p {color: #fff; font-size: 20px; margin: auto; padding: 100px 40px 20px; line-height: 30px !important; font-weight: 600;}
.down_contsec i {display: block; color: #fff; font-size: 20px; animation: arrow 0.3s infinite  alternate; -webkit-animation: arrow 0.3s infinite  alternate;}
  
@keyframes arrow {
  0% { transform: translateY(-10px); }
  100% { transform: translateY(-2px); }
}
@-webkit-keyframes arrow {
    0% { transform: translateY(-10px); }
    100% { transform: translateY(-2px); }
}

.click_heresec { margin: 50px 0 0 0;}
.click_heresec p {color: #fff; font-size: var(--title-size-h1); padding: 100px 50px 0; font-weight: var(--bold);}
/* .click_heresec img {margin: -7px 0 0 0;} */
/* .down_contsec img{ margin:0px auto 15px;} */

.commonbtn_sec {
    background: #a14d00;
    border: 2px solid #fff;
    border-radius: 75px;
    width: auto;
    display: inline-flex;
    margin: 0 auto 10px;
    padding: 12px 22px;
}
.commonbtn_sec img {width: 38px; height: 40px; margin: 0 10px 0 0;}
.commonbtn_sec h1 {font-size: var(--title-size-h1); text-transform: uppercase; font-weight: var(--bold); color: #fff; margin: auto;}
.commonbtn_sec h1 small {display: block; font-size: var(--font-size);}

.androidmainbtn_sec h1 {font-size: var(--title-size-h1);}
.androidmainbtn_sec h1 small {font-size: var(--font-size);}

.click_handsec {border: 1px solid #ffffff; width: 150px; display: flex; border-radius: 75px; text-align: center; padding: 10px; margin: auto; justify-content: center; align-items: center;
    height: 42px;}
.click_handsec h1 {font-size: var(--title-size-h1); text-transform: uppercase; font-weight: var(--bold); padding: 6px 0 0 0; color: #ffffff;}
.yiju_applogosec h1{ font-family: 'Abril Fatface', cursive; color: #fff; }

.yiju_applogosec {display: flex; margin: auto; width: fit-content;}
.yiju_applogosec img {margin: 0 10px 0 0;}
.yiju_applogosec h1 {padding: 13px 0 0 0;}
.openbrowser_sec {position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.6); color: #fff; z-index: 99;}
.close_openbrosec span {height: 40px; width: 40px; border-radius: 50%; background: #ff8d00; display: block; text-align: center; padding: 8px 0 0 0; font-size: var(--title-size-h1); margin: 10px;}
.arrow_browsersec span {font-size: 40px; float: right; color: var(--secondary-color); transform: rotate(-45deg); -webkit-transform: rotate(-45deg); animation: mover 0.3s infinite  alternate;-webkit-animation: mover 0.3s infinite  alternate;}
  @keyframes mover {
    0% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
  @-webkit-keyframes mover {
      0% { transform: translateY(-10px); }
      100% { transform: translateY(0px); }
  }
.arrow_browsersec {text-align: center; margin: 20px 0 0 0;}
.arrow_browsersec p {color: #fff; float: left; font-weight: var(--bold);}