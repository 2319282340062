.AppDetail {
  text-align: left;
}

.AppDetail-logo {
  height: 40vmin;
  pointer-events: none;
}

.AppDeatil-bar {
  background-color: gray;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .AppDetail-logo {
    animation: Detail-logo-spin infinite 20s linear;
  }
}

.AppDetail-header {
  background-color: #282c34;
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppDetail-body {
  background-color: white;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--title-color);
}

.AppDetail-footer {
  background-color: gray;
  min-height: 3vh;
  color: white;
}

.AppDetail-link {
  color: var(--a-color) ;
}

@keyframes Detial-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.line-price {
  text-decoration: line-through;
  font-weight: var(--normal);
  font-size: 90%;
}

.panel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 15px;
}
.D_Listing,.AppDet, .AppD, .AppDtl{margin-bottom: 130px;}
.D_Listing .back_page button i {position: relative; top: -3px; font-size: var(--title-size-h1);}

.backhome_floatsec {position: fixed; right: -3px; bottom: 150px; z-index: 99; background: #535c6f; color: #fff; border-radius: 50px 0px 0px 50px; padding: 8px; margin: 0; height: 43px;}
.backhome_floatsec p {color: #fff; font-size: 25px;}

.detail_banner_sec {margin-bottom: 15px;}
.detail_banner_sec .slick-next {right: 8px;background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px;}
.detail_banner_sec .slick-prev {left: 8px; background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px; z-index: 9;}
.detail_banner_sec .slick-prev:hover, 
.detail_banner_sec .slick-next:hover {background: rgba(240, 137, 45, 0.7);}
.detail_banner_sec .slick-prev:before {content: "\f060";}
.detail_banner_sec .slick-next:before {content: "\f061";}
.detail_banner_sec .slick-prev:before,
.detail_banner_sec .slick-next:before {font-family: "Font Awesome 5 Free"; font-weight: bold; font-size: var(--font-size); opacity: 1;}

span.prohis_leftsec {margin: 0 20px 0 0;}
span.prohis_leftsec h1 {font-size: var(--title-size-h1); font-weight: var(--bold); color: var(--primary-color) ;}
span.prohis_leftsec h1 small {font-weight: var(--normal); font-size: var(--font-size); color: var(--secondary-color) ;}
span.prohis_leftsec p {font-size: var(--font-size);}

span.prohis_rightsec {
  display: block;
  text-align: right;
  width: 25%;
}
span.prohis_rightsec p {
  color: var(--primary-color) ;
  font-size: var(--font-size);
  text-align: right;
  font-weight: var(--bold);
  background: var(--third-color);
  padding: 2px 7px;
  border-radius: 3px;
  display: initial;
}

span.prohis_rightsec button.propdetbtn {background: var(--secondary-color) ; color: #fff; padding: 4px 15px; border-radius: 50px;}
a[role="tab"] {color: var(--primary-text-color);}
a[role="tab"].active {
  color: var(--primary-color) !important;
  background: var(--third-color) !important;
}

.float_agent {
  z-index: 99999;
  color: white;
  background-color: var(--primary-color);
  box-shadow: 5px 5px 25px 10px rgb(0 0 0 / 15%);
  padding: 10px 15px;
  border-radius: 20px 20px 0 0;
  width: -webkit-fill-available;
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 130px;
  transition: all .5s ease;
}
.float_agent_top {
  width: 100%;
  height:10px;
  padding: 8px 0;
}
.float_agent_top_bar {
  margin: auto;
  width: 6%;
  border-top: 3px solid var(--third-color);
}
.float_agent_detail {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  align-items: center;
}
.float_agent .agent_ava {
  width: fit-content;
  height: auto;
  margin: auto;
}
.float_agent .agent_ava img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  min-width: 50px;
  min-height: 50px;
}
.float_agent .float_agent_d_left {
  display: grid;
  grid-template-columns: 50px auto;
  gap: 5px;
}

.float_agent .float_agent_d_left p,
.float_agent .badges_with_bk a{margin: 0;color: white;line-height: 1.4em !important;}
.float_agent a {color: #ffffff;font-size: var(--font-size);}
.float_agent .unimportant-txt {font-size: var(--font-size);opacity: .6;margin-bottom: -2px !important;  text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.float_agent .badges_with_bk i {font-size: 25px;margin: 0 5px;}
.float_agent .badges_with_bk {margin-left: auto;}
.float_agent .agent_info {width: 175px;min-height: 58.78px;}
.float_agent_domain {
  background: var(--third-color);
  padding: 0 3px;
  width: 100%;
  margin-top:10px;
  border-radius: 5px;
  min-height: 25px;
}
.float_agent .agent_name {
  margin-bottom: -3px !important;
}
.float_agent .float_agent_intro {
  color: white;
  margin-top: 1vh;
  margin-bottom: 0;
}
.float_agent_domain a{
  color: var(--primary-color);
}
.float_agent .float_agent_hidden {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  color: var(--white);
}
.float_agent .float_agent_hidden img {
  width:20%;
}
.float_agent.expand {
  max-height: 100%;
}
.float_agent .iconList {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.float_agent .float_agent_hidden_left {
  position: relative;
  display: flex;
  flex-direction: column;
  gap:.5em;
}
.float_agent .iconList svg{
  fill:#fff;
  width: 25px;
}
.float_agent .slogan {
  color:#fff;
  margin-bottom: auto;
}
p#pro-extras, div#pro-remarks {font-weight: normal;line-height: 19px; color: var(--font-color); font-size: var(--font-size);}
p#pro-parking {line-height: 18px;}

.accordion {background-color: var(--third-color) !important; color: var(--primary-color) !important; cursor: pointer; padding: 12px; width: 100%; border: none; text-align: left; outline: none;  font-size: var(--title-size-h2); 
  transition: 0.4s; border-radius: 4px; margin: 0px 0 0;}
 .accordion:after {content: "\f078"; font-weight: bold; float: right; margin-left: 5px; color: var(--primary-color)  !important; font-family: "Font Awesome 5 Free"; font-size: 10px; padding: 5px 0 0 0;}
.main_accordinsec .active:after {content: "\f077"; font-family: "Font Awesome 5 Free"; font-weight: bold; font-size: 10px; color: var(--title-color) !important;}
.main_accordinsec button.accordion i {font-size: var(--title-size-h2); color: var(--primary-color)  !important; width: 30px; text-align: center; margin: 0 5px 0 -5px; position: relative; top: 1px;}


.line-price {text-decoration: line-through; font-weight: var(--bold) !important; font-size: var(--font-size); color: var(--title-color) !important;}
.back_page {position: absolute; z-index: 99; top: -7px; left: 2px;}

.summary_contsec nav a.nav-link { display: block; padding: 6px 12px;}
.summary_contsec ul.nav-tabs{ justify-content: space-between;}
.summary_contsec ul.nav-tabs li {width: 33.0%; text-align: center;}
.summary_contsec ul.nav-tabs li a {border-color: #dee2e6 #dee2e6 #dee2e6 #dee2e6; border-radius: 5px 5px 0px 0px; padding: 7px 18px; font-size: var(--font-size); font-weight: var(--bold);
  color: #868686; text-transform: uppercase; cursor: pointer;}
.summary_contsec ul.nav-tabs li a.active{ color: #ffffff; background-color: var(--secondary-color) ;}

.inner_featiconsec{ display: none;}
.inner_featiconsec ul {display: flex; justify-content: space-around;}
.inner_featiconsec ul li {border: 1px solid #ffe1cd; border-radius: 5px; box-shadow: 0px 0px 5px #e6e4e4; min-width: 71px; text-align: center; padding: 5px;}
.inner_featiconsec ul li img {display: block; margin: auto;}

.property_pricesec {overflow: hidden; position: relative; margin: 0 0 13px 0;padding: 0 5px;}
.property_pricesec h1 {font-size: 27px; font-weight: var(--bold);}
.property_pricesec p {color: var(--font-color); margin: 0;}
.property_pricesec h2 {position: absolute; top: 0; right: 0; font-style: italic; font-weight: var(--bold); font-size: var(--font-size); background: var(--third-color); color: var(--primary-color); padding: 5px 12px;
  border-radius: 43px;}

span.sold_tag {position: absolute; top: 24px; left: 155px;}
span.sold_tag img {opacity: 0.65;}
.listing_detsec span.sold_tag {left: 60px;top:50px}

.proprice_infosec ul {display: flex; margin: 0; width: 100%; position: relative;}
.proprice_infosec ul li {float: left; margin-right: 5px; box-shadow: rgb(239, 239, 239) 0px 0px 4px; display: flex;
  background: rgb(255, 255, 255);
  border-width: 1px; border-style: solid; border-color: rgb(251, 233, 218); border-image: initial; border-radius: 50px; padding: 0px 6px 0px 0px;}
.proprice_infosec ul li i {background: var(--third-color);  color: var(--primary-color); border-radius: 50%; padding: 7px 0 0 7px; height: 28px;  width: 28px;  margin: 0px 1px 0 0; font-size: var(--font-size);
  position: relative; top: 0;}
.proprice_infosec ul li span {font-weight: var(--bold); padding: 0 2px 0 3px; font-size: var(--font-size); line-height: 28px !important;}
.proprice_infosec ul li.share_libtn {float: right; margin: 0;  background: var(--third-color); position: absolute; right: 0;}
.proprice_infosec ul li.share_libtn i {background: var(--primary-color); color: #ffffff; margin: 0 1px 0 0;}
.proprice_infosec ul li.share_libtn span {font-size: var(--font-size); text-transform: uppercase; font-weight: var(--bold);}

button#btn-back {background: none; font-weight: var(--bold); padding: 7px; color: #ffffff; border-radius: 50%; height: 30px; width: 30px; text-align: center; font-size: 25px; 
  border: none;}
.description-sec {
  padding-right: 0;
  margin-top: 10px;
}
.flex-container {
  display: flex;
  align-items: center;
}
.flex-container .report_chart_new_radiogrp {
  margin-top: 0;
  margin-bottom: 0;
  right: 10px;
  position: absolute;
  font-size: 10px;
  margin-bottom: 10px;
}
/* timeline custom */
.timeLine .timeLine_body{
  display: flex;
  color: var(--font-color);
  font-size: var(--font-size);
}
.timeLine .timeLine_body .timeLine_time {
  border-right: 1px solid var(--font-color);
  min-width:100px;
  padding-bottom: 10px;
}
.timeLine .timeLine_body .timeLine_action {
  padding-left: 15px;
}
.timeLine .timeLine_title {
  left: 4.9em;
  position: relative;
  margin-bottom: 0;
  padding:5px 0;
}
.timeLine .dot {
  width: 8px;
  height: 8px;
  background: var(--font-color);
  border-radius: 7px;
  position: relative;
  left: 6em;
}
.more_btn {
  text-align:center;
  background: #fff;
  box-shadow: 0px -10px 10px 0px #e6e4e4;
  padding: 5px 0;
  font-weight: var(--bold);
  color:var(--primary-color);
  margin-bottom: 20px;
}
span.eclipse-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: block;
  -webkit-box-orient: vertical;
  padding: 0;
  padding-left: 5px;
  white-space: nowrap;
}
.listing_detsec .listing_city {
  font-size: var(--font-size-small);
  padding-left: 20px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}