.main_searchsec {width: 100%; background: #fff3e8; border: 0px solid #ffdec0; border-radius: 0px; margin-right: 0;}
.main_selectsec {width: 29%; display: none;}
.map_searchsec {position: absolute; top: 0px; right: 0px;}
.main_searchsec input {background: none; border: none; padding: 11px 11px 11px 20px; font-size: 14px; width: 93%;}
.main_searchsec img {position: relative; left: 10px; top: 0px;}
.main_selectsec select {background: none; border: none; padding: 10px 0px 10px 5px; width: 98%;}

.map_searchsec button.btn-primary {background-color: var(--secondary-color) !important; height: 44px; border: 0px solid #ffffff; display: block; width: 45px; border-radius: 0px; text-align: center; font-size: var(--title-size-h1);  color: #ffffff; padding-top: 8px;}
.map_searchsec button.btn-primary:hover, .map_searchsec button.btn-primary:focus{ background-color: var(--secondary-color) !important;}

.map_searchsec a {background: var(--secondary-color); height: 44px; border: 0px solid #ffffff; display: block; width: 45px; border-radius: 0px; text-align: center; font-size: var(--title-size-h1);  color: #ffffff; padding-top: 8px;}
.map_searchsec a:hover{ color: #ffffff;}

.property_searchsec { position: relative; padding: 0; margin: 0px 0 15px; /* display: flex; */ /* justify-content: space-between; */}
.categorydet_sec ul {text-align: center; padding: 0 0 15px 0; display: flex; flex-wrap: nowrap; justify-content: space-between; margin: 0 0px 6px 0px;}
.categorydet_sec ul:first-child {padding-top: 0px;}
.categorydet_sec ul:last-child {padding-bottom: 0px;}

.categorydet_sec ul li:first-child {margin-left: 0px;}
.categorydet_sec ul li:last-child {margin-right: 0px;}
.categorydet_sec ul li {margin: 0px 0px; padding: 0; width: 20%;}
.categorydet_sec ul li button {background-color: rgba(0, 0, 0, 0) !important; border: none; margin: 0; padding: 0; display: block; margin: auto; padding: 0; width: 100%;}
.categorydet_sec ul li p {text-transform: capitalize; font-weight: var(--bold); font-size: var(--font-size-small); color: var(--primary-color); margin: 0; line-height: 14px !important;}
.apphome_imgsec {background: #fff3e8; border-radius: 50%; height: 45px; width: 45px; text-align: center; margin: 0 auto 5px; padding: 10px;}
.categorydet_sec ul li img {padding: 0; margin: 0 0 8px 0; opacity: 1; width: 30px;}
.update_secs {display: flex; justify-content: space-between;}
.innerupdate_sec {position: relative;}
.innerupdate_sec h2 {position: absolute; top: 10px; font-size: var(--font-size); font-weight: 700; line-height: 15px; left: 6px; text-transform: uppercase;}
.marketrend_sec {background: #fd8415; position: relative; padding: 24px 0 0 8px; height: 107px; overflow: hidden;}
.marketrend_sec button {margin: 0; padding: 0; background-color: rgba(0, 0, 0, 0); border: none; text-align: left; position: relative; width: 100%;}
.marketrend_sec .btn-primary:not(:disabled):not(.disabled).active, .marketrend_sec .btn-primary:not(:disabled):not(.disabled):active, .marketrend_sec .show>.btn-primary.dropdown-toggle{ background-color: rgba(0, 0, 0, 0) !important; border: none !important; box-shadow: none !important;}
.marketrend_sec .btn-primary:hover, .marketrend_sec .btn-primary:focus{ background-color: rgba(0, 0, 0, 0) !important; border: none !important; box-shadow: none !important;}

.marketrend_sec h1 {font-size: var(--title-size-h1); text-transform: uppercase; font-weight: 700; color: #ffffff; margin: 0px 0 0 0; position: relative; z-index: 111;}
.marketrend_sec p {color:var(--white); font-size: var(--font-size);}
.marketrend_sec img {position: absolute; right: 0; top: -24px;}