#condos0_list .inner_listingsec {
  width: 100%;
  margin: 0 0 20px 0;
}
#condos0_list .inner_listingsec .listing_imgsec {
  height: 250px;
}
#condos0_list .inner_listingsec .listing_imgsec img {
  height: 100%;
}
#condos1_list .inner_listingsec {
  width: 100%;
  margin: 0 0 20px 0;
}
#condos1_list .inner_listingsec .listing_imgsec {
  height: 250px;
}
#condos1_list .inner_listingsec .listing_imgsec img {
  height: 100%;
}

.news-title {
  font-size: 150%;
}

.detail_banner_sec {margin-bottom: 15px;}
.detail_banner_sec .slick-next {right: 8px; background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px;}
.detail_banner_sec .slick-prev {left: 8px; background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px; z-index: 9;}
.detail_banner_sec .slick-prev:hover, 
.detail_banner_sec .slick-next:hover {background: rgba(240, 137, 45, 0.7);}
.detail_banner_sec .slick-prev:before {content: "\f060";}
.detail_banner_sec .slick-next:before {content: "\f061";}
.detail_banner_sec .slick-prev:before,
.detail_banner_sec .slick-next:before {font-family: "Font Awesome 5 Free"; font-weight: bold; font-size: var(--font-size); opacity: 1;}
div#condosContent {background: var(--third-color); padding: 10px;}
div#condosContent p {margin: 0 0 8px 0; padding: 0; /* font-size: 16px !important; */}
#condosContent iframe{ width: 100% !important;}
#news_list{ margin: 15px 0 0 0;}
#news_list li {background: #fff3e8; border: 1px solid #ffead7; padding: 0; margin: 0 0 15px 0; overflow: hidden;}
.home_articlesec {padding: 9px 10px 0 0; float: right; width: 48%;}
.home_articlesec h2 {font-weight: var(--bold); color: var(--primary-color) ; font-size: var(--title-size-h2); line-height: 24px;}
img.newart_imgsec {width: 45%; padding: 0; border: 0px solid #fff; margin: 10px; float: left;}