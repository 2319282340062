/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.AppDetail {
  text-align: left;
}

.AppDetail-logo {
  height: 40vmin;
  pointer-events: none;
}

.AppDeatil-bar {
  background-color: gray;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .AppDetail-logo {
    animation: Detail-logo-spin infinite 20s linear;
  }
}

.AppDetail-header {
  background-color: #282c34;
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppDetail-body {
  background-color: white;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--title-color);
}

.AppDetail-footer {
  background-color: gray;
  min-height: 3vh;
  color: white;
}

.AppDetail-link {
  color: var(--a-color) ;
}

@keyframes Detial-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.line-price {
  text-decoration: line-through;
  font-weight: var(--normal);
  font-size: 90%;
}

.panel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 15px;
}
.D_Listing,.AppDet, .AppD, .AppDtl{margin-bottom: 130px;}
.D_Listing .back_page button i {position: relative; top: -3px; font-size: var(--title-size-h1);}

.backhome_floatsec {position: fixed; right: -3px; bottom: 150px; z-index: 99; background: #535c6f; color: #fff; border-radius: 50px 0px 0px 50px; padding: 8px; margin: 0; height: 43px;}
.backhome_floatsec p {color: #fff; font-size: 25px;}

.detail_banner_sec {margin-bottom: 15px;}
.detail_banner_sec .slick-next {right: 8px;background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px;}
.detail_banner_sec .slick-prev {left: 8px; background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px; z-index: 9;}
.detail_banner_sec .slick-prev:hover, 
.detail_banner_sec .slick-next:hover {background: rgba(240, 137, 45, 0.7);}
.detail_banner_sec .slick-prev:before {content: "\F060";}
.detail_banner_sec .slick-next:before {content: "\F061";}
.detail_banner_sec .slick-prev:before,
.detail_banner_sec .slick-next:before {font-family: "Font Awesome 5 Free"; font-weight: bold; font-size: var(--font-size); opacity: 1;}

span.prohis_leftsec {margin: 0 20px 0 0;}
span.prohis_leftsec h1 {font-size: var(--title-size-h1); font-weight: var(--bold); color: var(--primary-color) ;}
span.prohis_leftsec h1 small {font-weight: var(--normal); font-size: var(--font-size); color: var(--secondary-color) ;}
span.prohis_leftsec p {font-size: var(--font-size);}

span.prohis_rightsec {
  display: block;
  text-align: right;
  width: 25%;
}
span.prohis_rightsec p {
  color: var(--primary-color) ;
  font-size: var(--font-size);
  text-align: right;
  font-weight: var(--bold);
  background: var(--third-color);
  padding: 2px 7px;
  border-radius: 3px;
  display: inline;
  display: initial;
}

span.prohis_rightsec button.propdetbtn {background: var(--secondary-color) ; color: #fff; padding: 4px 15px; border-radius: 50px;}
a[role="tab"] {color: var(--primary-text-color);}
a[role="tab"].active {
  color: var(--primary-color) !important;
  background: var(--third-color) !important;
}

.float_agent {
  z-index: 99999;
  color: white;
  background-color: var(--primary-color);
  box-shadow: 5px 5px 25px 10px rgb(0 0 0 / 15%);
  padding: 10px 15px;
  border-radius: 20px 20px 0 0;
  width: -webkit-fill-available;
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 130px;
  transition: all .5s ease;
}
.float_agent_top {
  width: 100%;
  height:10px;
  padding: 8px 0;
}
.float_agent_top_bar {
  margin: auto;
  width: 6%;
  border-top: 3px solid var(--third-color);
}
.float_agent_detail {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.float_agent .agent_ava {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  margin: auto;
}
.float_agent .agent_ava img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  min-width: 50px;
  min-height: 50px;
}
.float_agent .float_agent_d_left {
  display: grid;
  grid-template-columns: 50px auto;
  grid-gap: 5px;
  gap: 5px;
}

.float_agent .float_agent_d_left p,
.float_agent .badges_with_bk a{margin: 0;color: white;line-height: 1.4em !important;}
.float_agent a {color: #ffffff;font-size: var(--font-size);}
.float_agent .unimportant-txt {font-size: var(--font-size);opacity: .6;margin-bottom: -2px !important;  text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.float_agent .badges_with_bk i {font-size: 25px;margin: 0 5px;}
.float_agent .badges_with_bk {margin-left: auto;}
.float_agent .agent_info {width: 175px;min-height: 58.78px;}
.float_agent_domain {
  background: var(--third-color);
  padding: 0 3px;
  width: 100%;
  margin-top:10px;
  border-radius: 5px;
  min-height: 25px;
}
.float_agent .agent_name {
  margin-bottom: -3px !important;
}
.float_agent .float_agent_intro {
  color: white;
  margin-top: 1vh;
  margin-bottom: 0;
}
.float_agent_domain a{
  color: var(--primary-color);
}
.float_agent .float_agent_hidden {
  margin-top: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  color: var(--white);
}
.float_agent .float_agent_hidden img {
  width:20%;
}
.float_agent.expand {
  max-height: 100%;
}
.float_agent .iconList {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.float_agent .float_agent_hidden_left {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap:.5em;
  gap:.5em;
}
.float_agent .iconList svg{
  fill:#fff;
  width: 25px;
}
.float_agent .slogan {
  color:#fff;
  margin-bottom: auto;
}
p#pro-extras, div#pro-remarks {font-weight: normal;line-height: 19px; color: var(--font-color); font-size: var(--font-size);}
p#pro-parking {line-height: 18px;}

.accordion {background-color: var(--third-color) !important; color: var(--primary-color) !important; cursor: pointer; padding: 12px; width: 100%; border: none; text-align: left; outline: none;  font-size: var(--title-size-h2); 
  transition: 0.4s; border-radius: 4px; margin: 0px 0 0;}
 .accordion:after {content: "\F078"; font-weight: bold; float: right; margin-left: 5px; color: var(--primary-color)  !important; font-family: "Font Awesome 5 Free"; font-size: 10px; padding: 5px 0 0 0;}
.main_accordinsec .active:after {content: "\F077"; font-family: "Font Awesome 5 Free"; font-weight: bold; font-size: 10px; color: var(--title-color) !important;}
.main_accordinsec button.accordion i {font-size: var(--title-size-h2); color: var(--primary-color)  !important; width: 30px; text-align: center; margin: 0 5px 0 -5px; position: relative; top: 1px;}


.line-price {text-decoration: line-through; font-weight: var(--bold) !important; font-size: var(--font-size); color: var(--title-color) !important;}
.back_page {position: absolute; z-index: 99; top: -7px; left: 2px;}

.summary_contsec nav a.nav-link { display: block; padding: 6px 12px;}
.summary_contsec ul.nav-tabs{ justify-content: space-between;}
.summary_contsec ul.nav-tabs li {width: 33.0%; text-align: center;}
.summary_contsec ul.nav-tabs li a {border-color: #dee2e6 #dee2e6 #dee2e6 #dee2e6; border-radius: 5px 5px 0px 0px; padding: 7px 18px; font-size: var(--font-size); font-weight: var(--bold);
  color: #868686; text-transform: uppercase; cursor: pointer;}
.summary_contsec ul.nav-tabs li a.active{ color: #ffffff; background-color: var(--secondary-color) ;}

.inner_featiconsec{ display: none;}
.inner_featiconsec ul {display: flex; justify-content: space-around;}
.inner_featiconsec ul li {border: 1px solid #ffe1cd; border-radius: 5px; box-shadow: 0px 0px 5px #e6e4e4; min-width: 71px; text-align: center; padding: 5px;}
.inner_featiconsec ul li img {display: block; margin: auto;}

.property_pricesec {overflow: hidden; position: relative; margin: 0 0 13px 0;padding: 0 5px;}
.property_pricesec h1 {font-size: 27px; font-weight: var(--bold);}
.property_pricesec p {color: var(--font-color); margin: 0;}
.property_pricesec h2 {position: absolute; top: 0; right: 0; font-style: italic; font-weight: var(--bold); font-size: var(--font-size); background: var(--third-color); color: var(--primary-color); padding: 5px 12px;
  border-radius: 43px;}

span.sold_tag {position: absolute; top: 24px; left: 155px;}
span.sold_tag img {opacity: 0.65;}
.listing_detsec span.sold_tag {left: 60px;top:50px}

.proprice_infosec ul {display: flex; margin: 0; width: 100%; position: relative;}
.proprice_infosec ul li {float: left; margin-right: 5px; box-shadow: rgb(239, 239, 239) 0px 0px 4px; display: flex;
  background: rgb(255, 255, 255);
  border-width: 1px; border-style: solid; border-color: rgb(251, 233, 218); border-image: none; border-image: initial; border-radius: 50px; padding: 0px 6px 0px 0px;}
.proprice_infosec ul li i {background: var(--third-color);  color: var(--primary-color); border-radius: 50%; padding: 7px 0 0 7px; height: 28px;  width: 28px;  margin: 0px 1px 0 0; font-size: var(--font-size);
  position: relative; top: 0;}
.proprice_infosec ul li span {font-weight: var(--bold); padding: 0 2px 0 3px; font-size: var(--font-size); line-height: 28px !important;}
.proprice_infosec ul li.share_libtn {float: right; margin: 0;  background: var(--third-color); position: absolute; right: 0;}
.proprice_infosec ul li.share_libtn i {background: var(--primary-color); color: #ffffff; margin: 0 1px 0 0;}
.proprice_infosec ul li.share_libtn span {font-size: var(--font-size); text-transform: uppercase; font-weight: var(--bold);}

button#btn-back {background: none; font-weight: var(--bold); padding: 7px; color: #ffffff; border-radius: 50%; height: 30px; width: 30px; text-align: center; font-size: 25px; 
  border: none;}
.description-sec {
  padding-right: 0;
  margin-top: 10px;
}
.flex-container {
  display: flex;
  align-items: center;
}
.flex-container .report_chart_new_radiogrp {
  margin-top: 0;
  margin-bottom: 0;
  right: 10px;
  position: absolute;
  font-size: 10px;
  margin-bottom: 10px;
}
/* timeline custom */
.timeLine .timeLine_body{
  display: flex;
  color: var(--font-color);
  font-size: var(--font-size);
}
.timeLine .timeLine_body .timeLine_time {
  border-right: 1px solid var(--font-color);
  min-width:100px;
  padding-bottom: 10px;
}
.timeLine .timeLine_body .timeLine_action {
  padding-left: 15px;
}
.timeLine .timeLine_title {
  left: 4.9em;
  position: relative;
  margin-bottom: 0;
  padding:5px 0;
}
.timeLine .dot {
  width: 8px;
  height: 8px;
  background: var(--font-color);
  border-radius: 7px;
  position: relative;
  left: 6em;
}
.more_btn {
  text-align:center;
  background: #fff;
  box-shadow: 0px -10px 10px 0px #e6e4e4;
  padding: 5px 0;
  font-weight: var(--bold);
  color:var(--primary-color);
  margin-bottom: 20px;
}
span.eclipse-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: block;
  -webkit-box-orient: vertical;
  padding: 0;
  padding-left: 5px;
  white-space: nowrap;
}
.listing_detsec .listing_city {
  font-size: var(--font-size-small);
  padding-left: 20px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.groupbutton-scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.title_left {
    width: "50%";
    float: "left";
}

.title_right {
    width: "50%";
    float: "left";
    text-align: "end";
}
.hori_select {
  margin-top: 10px;
  display: -webkit-box;
  overflow-x: scroll;
  grid-gap: 10px;
  gap: 10px;
}
.hori_select .hori_items {
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 5px;
}
.hori_select .hori_items.active {
  background-color: var(--primary-color);
  color: #fff;
}
.groupbutton-scrolling-wrapper .btn-secondary:hover,
.groupbutton-scrolling-wrapper .btn-secondary:focus {
  background-color: #404d58 !important;
}

/**graph_pagecss**/
.groupbutton-scrolling-wrapper {
  margin: 0 10px;
}
.MuiFormGroup-root {
  margin: 0 !important;
}
div#region_city {
  margin: 11px 10px 0px;
}
/* div#listing_type{ margin: 0px 10px;} */
.MuiRadio-colorPrimary.Mui-checked {
  color: var(--primary-color) !important;
}
.css-yk16xz-control {
  border-color: var(--third-color) !important;
  background: var(--third-color) !important;
  margin: 0 0 7px 0;
}
.css-1uccc91-singleValue {
  font-size: var(--font-size);
}
.css-6q0nyr-Svg {
  fill: var(--primary-color) !important;
}
#year_range,
#sold_leased {
  margin: -8px 0 -11px 10px;
}
.MuiFormGroup-root label {
  margin: 0 2px 0 0;
  background: #ffe9d7;
  border-radius: 5px;
  padding: 0 15px;
  text-align: center;
}

div#title_stats div:first-child {
  /* font-weight: var(--bold); */
  color: var(--primary-color) ;
  font-size: var(--font-size);
}
/* div#title_stats div {border-bottom: 1px solid #efefef; padding: 5px 0 5px;} */

div#app_title {
  width: 97% !important;
}
div#title_stats {
  background: var(--third-color);
  padding: 10px;
  border-radius: 4px;
}

div#title_median {
  margin: -15px 0 0 0;
  background: var(--third-color);
  padding: 10px;
  border-radius: 4px;
}
#tit_median {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  font-size: var(--font-size);
}
#tit_median div:first-child {
  border-right: 1px solid;
}
#tit_median b {
  color: var(--primary-color) ;
}
.regular_color {
  color: var(--title-color) !important;
}
div#title_block {
  /* font-weight: var(--bold); */
  color: var(--primary-color) ;
  font-size: var(--font-size);
}

.block_title {
  font-size: 32;
}
.detail_chart div#title_stats {
  background: transparent;
  padding: 0;
  border-radius: 0;
}
.detail_chart div#title_median {
  margin: -15px 0 0 0;
  background: transparent;
  padding: 0;
  border-radius: 0;
}
.margin_bottom {margin-bottom: 10px;}
.report_chart_new_radiogrp .active {
  color: white;
  background: var(--primary-color) ;
  padding: 0 5px;}
.report_chart_new_radiogrp span {
  border: 1px solid var(--primary-color); 
  padding: 0 5px;
  color:var(--primary-color);
}
.report_chart_new_radiogrp {margin-bottom: -10px;margin-top: 20px;display: flex;grid-gap: 10px;gap: 10px;}
.main_searchsec {width: 100%; background: #fff3e8; border: 0px solid #ffdec0; border-radius: 0px; margin-right: 0;}
.main_selectsec {width: 29%; display: none;}
.map_searchsec {position: absolute; top: 0px; right: 0px;}
.main_searchsec input {background: none; border: none; padding: 11px 11px 11px 20px; font-size: 14px; width: 93%;}
.main_searchsec img {position: relative; left: 10px; top: 0px;}
.main_selectsec select {background: none; border: none; padding: 10px 0px 10px 5px; width: 98%;}

.map_searchsec button.btn-primary {background-color: var(--secondary-color) !important; height: 44px; border: 0px solid #ffffff; display: block; width: 45px; border-radius: 0px; text-align: center; font-size: var(--title-size-h1);  color: #ffffff; padding-top: 8px;}
.map_searchsec button.btn-primary:hover, .map_searchsec button.btn-primary:focus{ background-color: var(--secondary-color) !important;}

.map_searchsec a {background: var(--secondary-color); height: 44px; border: 0px solid #ffffff; display: block; width: 45px; border-radius: 0px; text-align: center; font-size: var(--title-size-h1);  color: #ffffff; padding-top: 8px;}
.map_searchsec a:hover{ color: #ffffff;}

.property_searchsec { position: relative; padding: 0; margin: 0px 0 15px; /* display: flex; */ /* justify-content: space-between; */}
.categorydet_sec ul {text-align: center; padding: 0 0 15px 0; display: flex; flex-wrap: nowrap; justify-content: space-between; margin: 0 0px 6px 0px;}
.categorydet_sec ul:first-child {padding-top: 0px;}
.categorydet_sec ul:last-child {padding-bottom: 0px;}

.categorydet_sec ul li:first-child {margin-left: 0px;}
.categorydet_sec ul li:last-child {margin-right: 0px;}
.categorydet_sec ul li {margin: 0px 0px; padding: 0; width: 20%;}
.categorydet_sec ul li button {background-color: rgba(0, 0, 0, 0) !important; border: none; margin: 0; padding: 0; display: block; margin: auto; padding: 0; width: 100%;}
.categorydet_sec ul li p {text-transform: capitalize; font-weight: var(--bold); font-size: var(--font-size-small); color: var(--primary-color); margin: 0; line-height: 14px !important;}
.apphome_imgsec {background: #fff3e8; border-radius: 50%; height: 45px; width: 45px; text-align: center; margin: 0 auto 5px; padding: 10px;}
.categorydet_sec ul li img {padding: 0; margin: 0 0 8px 0; opacity: 1; width: 30px;}
.update_secs {display: flex; justify-content: space-between;}
.innerupdate_sec {position: relative;}
.innerupdate_sec h2 {position: absolute; top: 10px; font-size: var(--font-size); font-weight: 700; line-height: 15px; left: 6px; text-transform: uppercase;}
.marketrend_sec {background: #fd8415; position: relative; padding: 24px 0 0 8px; height: 107px; overflow: hidden;}
.marketrend_sec button {margin: 0; padding: 0; background-color: rgba(0, 0, 0, 0); border: none; text-align: left; position: relative; width: 100%;}
.marketrend_sec .btn-primary:not(:disabled):not(.disabled).active, .marketrend_sec .btn-primary:not(:disabled):not(.disabled):active, .marketrend_sec .show>.btn-primary.dropdown-toggle{ background-color: rgba(0, 0, 0, 0) !important; border: none !important; box-shadow: none !important;}
.marketrend_sec .btn-primary:hover, .marketrend_sec .btn-primary:focus{ background-color: rgba(0, 0, 0, 0) !important; border: none !important; box-shadow: none !important;}

.marketrend_sec h1 {font-size: var(--title-size-h1); text-transform: uppercase; font-weight: 700; color: #ffffff; margin: 0px 0 0 0; position: relative; z-index: 111;}
.marketrend_sec p {color:var(--white); font-size: var(--font-size);}
.marketrend_sec img {position: absolute; right: 0; top: -24px;}

.allouter_agentdet_sec {
  overflow: hidden;
  background: #f7f7f7;
  border-radius: 6px;
  border: 1px solid #eaeaea;
  padding: 10px 10px 0px 10px;
  margin: 0 0 10px 0;
}
.left_agentdetsec {width: 25%; float: left;}
.right_agentdetsec {width: 73%; float: right;}
.right_agentdetsec .main_agentdetsec {width: 100%;}
.right_agentdetsec .main_agentdetsec h2{font-size: var(--title-size-h2); font-weight: var(--bold); line-height: 15px; margin: 0;}
.right_agentdetsec .main_agentdetsec h3 {font-size: var(--font-size); padding: 0; color: var(--primary-color); background: none;  border:none}
.right_agentdetsec .main_agentdetsec p {font-size: var(--font-size); line-height: 17px !important; padding: 0; margin: 0;}
.right_agentdetsec .agent_description_mainsec {width: 100%;}
.right_agentdetsec .agent_description_mainsec p button {color: var(--title-color); padding: 0; font-weight: var(--bold); border-radius: 0px; text-align: center; font-size: var(--font-size); padding: 3px 3px; margin: 0;}
.right_agentdetsec .agent_description_mainsec p button i {font-size: var(--font-size);}
.right_agentdetsec .agent_description_mainsec p {font-size: var(--font-size); line-height: 18px !important; margin: 5px 0 0 0;}
.right_agentdetsec .agentfull_detsec ul li a {border: 1px solid #cecece; padding: 0px 5px;}
.right_agentdetsec .agentfull_detsec ul li i {color: var(--secondary-color); padding: 0; border-radius: 3px; margin: 5px 4px 0 1px; font-size: var(--font-size);}
.right_agentdetsec .agentfull_detsec ul li p {margin: 0; padding: 0;  line-height: 23px !important; font-size: var(--font-size-small); color: var(--title-color); text-transform: capitalize;}
.agent_fulldesc_sec{ display: none;}
.agent_fulldesc_sec p{ line-height: 16px !important;}
.inner_developersec {
  background: #f9f9f9;
  border-radius: 6px;
  margin: 0 0 10px 0;
  padding: 13px;
  border: 1px solid #f1f1f1;
}

.inner_developersec h1 {
  font-size: var(--title-size-h1);
  font-weight: var(--bold);
}

.inner_developersec p {
  font-size: var(--font-size);
  line-height: 20px;
  display: block;
}

.inner_developersec p button {
  color: var(--title-color);
  padding: 0;
  font-weight: var(--bold);
  border-radius: 0px;
  text-align: center;
  font-size: var(--font-size);
  padding: 3px 3px;
  margin: 0;
}
.loginrequire {
  position: relative;
  min-height: 700px;
  height: 100%;
}
span#regist_showpwd {display: inline-block; float: right; position: relative; top: -40px; right: 12px; margin: 0 0 -23px 0; }
.realtor_checkbox {display: flex; align-items: center;}
input#inputIsRealtor {height: auto; padding: 13px; margin: 0 10px 0 0; width: auto;}
#condos0_list .inner_listingsec {
  width: 100%;
  margin: 0 0 20px 0;
}
#condos0_list .inner_listingsec .listing_imgsec {
  height: 250px;
}
#condos0_list .inner_listingsec .listing_imgsec img {
  height: 100%;
}
#condos1_list .inner_listingsec {
  width: 100%;
  margin: 0 0 20px 0;
}
#condos1_list .inner_listingsec .listing_imgsec {
  height: 250px;
}
#condos1_list .inner_listingsec .listing_imgsec img {
  height: 100%;
}

.news-title {
  font-size: 150%;
}

.detail_banner_sec {margin-bottom: 15px;}
.detail_banner_sec .slick-next {right: 8px; background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px;}
.detail_banner_sec .slick-prev {left: 8px; background: rgba(240, 137, 45, 0.7); border-radius: 50%; height: 25px; width: 25px; z-index: 9;}
.detail_banner_sec .slick-prev:hover, 
.detail_banner_sec .slick-next:hover {background: rgba(240, 137, 45, 0.7);}
.detail_banner_sec .slick-prev:before {content: "\F060";}
.detail_banner_sec .slick-next:before {content: "\F061";}
.detail_banner_sec .slick-prev:before,
.detail_banner_sec .slick-next:before {font-family: "Font Awesome 5 Free"; font-weight: bold; font-size: var(--font-size); opacity: 1;}
div#condosContent {background: var(--third-color); padding: 10px;}
div#condosContent p {margin: 0 0 8px 0; padding: 0; /* font-size: 16px !important; */}
#condosContent iframe{ width: 100% !important;}
#news_list{ margin: 15px 0 0 0;}
#news_list li {background: #fff3e8; border: 1px solid #ffead7; padding: 0; margin: 0 0 15px 0; overflow: hidden;}
.home_articlesec {padding: 9px 10px 0 0; float: right; width: 48%;}
.home_articlesec h2 {font-weight: var(--bold); color: var(--primary-color) ; font-size: var(--title-size-h2); line-height: 24px;}
img.newart_imgsec {width: 45%; padding: 0; border: 0px solid #fff; margin: 10px; float: left;}
.shared_title {
  font-size: var(--title-size-h1);
  font-weight: var(--bold);
}

.shared_content {
  line-height: 30px;
  font-size: var(--title-size-h2);
}

.loginrequire {
  position: relative;
  min-height: 700px;
  height: 100%;
}
.topshare_sec {width: 70%; margin: -45px 0 0 0;}
button.remove_crossec {background: var(--primary-color); border-radius: 3px; color: #fff; font-weight: var(--bold); font-size: var(--font-size); position: relative; padding: 3px 6px; margin: 0 0 5px 0;}

.brief_textarea{ height: 250px;}
.my-component{ margin: 10px;}
.media_inputext {display: block; background: #fbfbfb; width: 100%; padding: 11px; border-radius: 5px; margin: 8px 0 10px 0px; border: 1px solid #efefef;}
.shared_msgs_list .new_pagelink_btn {width: 48%; margin: 5px 0 10px 5px; display: block; padding: 10px !important; font-size: var(--font-size) !important; text-transform: uppercase; font-weight: var(--bold);
  float: left; background-color: var(--secondary-color)  !important; color: #fff !important;}
  
.shared_msgs_list .new_pagelink_btn i {font-size: var(--title-size-h1); margin: 0; display: block; top: 0!important; left: 0!important;}
.shared_msgs_list .panel {padding: 0 5px; background-color: #ffffff; max-height: inherit; overflow: visible; transition: max-height 0.2s ease-out; margin: 0px 0 0 0; display: inline-block;
  width: 100%;}
.shared_msgs_list ul.main_historysec li{ display: block;}

.download_mainsppsec {background: url(/css/images/mainbg_app.png) center center no-repeat; min-height: 645px; width: 100%; display: flex; justify-content: center;
    align-items: center; position: absolute; height: 100%; background-size: cover;}
.inner_downloadsec {text-align: center;}
.down_contsec p {color: #fff; font-size: 20px; margin: auto; padding: 100px 40px 20px; line-height: 30px !important; font-weight: 600;}
.down_contsec i {display: block; color: #fff; font-size: 20px; animation: arrow 0.3s infinite  alternate; -webkit-animation: arrow 0.3s infinite  alternate;}
  
@keyframes arrow {
  0% { transform: translateY(-10px); }
  100% { transform: translateY(-2px); }
}

.click_heresec { margin: 50px 0 0 0;}
.click_heresec p {color: #fff; font-size: var(--title-size-h1); padding: 100px 50px 0; font-weight: var(--bold);}
/* .click_heresec img {margin: -7px 0 0 0;} */
/* .down_contsec img{ margin:0px auto 15px;} */

.commonbtn_sec {
    background: #a14d00;
    border: 2px solid #fff;
    border-radius: 75px;
    width: auto;
    display: inline-flex;
    margin: 0 auto 10px;
    padding: 12px 22px;
}
.commonbtn_sec img {width: 38px; height: 40px; margin: 0 10px 0 0;}
.commonbtn_sec h1 {font-size: var(--title-size-h1); text-transform: uppercase; font-weight: var(--bold); color: #fff; margin: auto;}
.commonbtn_sec h1 small {display: block; font-size: var(--font-size);}

.androidmainbtn_sec h1 {font-size: var(--title-size-h1);}
.androidmainbtn_sec h1 small {font-size: var(--font-size);}

.click_handsec {border: 1px solid #ffffff; width: 150px; display: flex; border-radius: 75px; text-align: center; padding: 10px; margin: auto; justify-content: center; align-items: center;
    height: 42px;}
.click_handsec h1 {font-size: var(--title-size-h1); text-transform: uppercase; font-weight: var(--bold); padding: 6px 0 0 0; color: #ffffff;}
.yiju_applogosec h1{ font-family: 'Abril Fatface', cursive; color: #fff; }

.yiju_applogosec {display: flex; margin: auto; width: -webkit-fit-content; width: -moz-fit-content; width: fit-content;}
.yiju_applogosec img {margin: 0 10px 0 0;}
.yiju_applogosec h1 {padding: 13px 0 0 0;}
.openbrowser_sec {position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.6); color: #fff; z-index: 99;}
.close_openbrosec span {height: 40px; width: 40px; border-radius: 50%; background: #ff8d00; display: block; text-align: center; padding: 8px 0 0 0; font-size: var(--title-size-h1); margin: 10px;}
.arrow_browsersec span {font-size: 40px; float: right; color: var(--secondary-color); transform: rotate(-45deg); -webkit-transform: rotate(-45deg); animation: mover 0.3s infinite  alternate;-webkit-animation: mover 0.3s infinite  alternate;}
  @keyframes mover {
    0% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
.arrow_browsersec {text-align: center; margin: 20px 0 0 0;}
.arrow_browsersec p {color: #fff; float: left; font-weight: var(--bold);}
