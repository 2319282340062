.shared_title {
  font-size: var(--title-size-h1);
  font-weight: var(--bold);
}

.shared_content {
  line-height: 30px;
  font-size: var(--title-size-h2);
}

.loginrequire {
  position: relative;
  min-height: 700px;
  height: 100%;
}
.topshare_sec {width: 70%; margin: -45px 0 0 0;}
button.remove_crossec {background: var(--primary-color); border-radius: 3px; color: #fff; font-weight: var(--bold); font-size: var(--font-size); position: relative; padding: 3px 6px; margin: 0 0 5px 0;}

.brief_textarea{ height: 250px;}
.my-component{ margin: 10px;}
.media_inputext {display: block; background: #fbfbfb; width: 100%; padding: 11px; border-radius: 5px; margin: 8px 0 10px 0px; border: 1px solid #efefef;}
.shared_msgs_list .new_pagelink_btn {width: 48%; margin: 5px 0 10px 5px; display: block; padding: 10px !important; font-size: var(--font-size) !important; text-transform: uppercase; font-weight: var(--bold);
  float: left; background-color: var(--secondary-color)  !important; color: #fff !important;}
  
.shared_msgs_list .new_pagelink_btn i {font-size: var(--title-size-h1); margin: 0; display: block; top: 0!important; left: 0!important;}
.shared_msgs_list .panel {padding: 0 5px; background-color: #ffffff; max-height: inherit; overflow: visible; transition: max-height 0.2s ease-out; margin: 0px 0 0 0; display: inline-block;
  width: 100%;}
.shared_msgs_list ul.main_historysec li{ display: block;}