.groupbutton-scrolling-wrapper .btn-secondary:hover,
.groupbutton-scrolling-wrapper .btn-secondary:focus {
  background-color: #404d58 !important;
}

/**graph_pagecss**/
.groupbutton-scrolling-wrapper {
  margin: 0 10px;
}
.MuiFormGroup-root {
  margin: 0 !important;
}
div#region_city {
  margin: 11px 10px 0px;
}
/* div#listing_type{ margin: 0px 10px;} */
.MuiRadio-colorPrimary.Mui-checked {
  color: var(--primary-color) !important;
}
.css-yk16xz-control {
  border-color: var(--third-color) !important;
  background: var(--third-color) !important;
  margin: 0 0 7px 0;
}
.css-1uccc91-singleValue {
  font-size: var(--font-size);
}
.css-6q0nyr-Svg {
  fill: var(--primary-color) !important;
}
#year_range,
#sold_leased {
  margin: -8px 0 -11px 10px;
}
.MuiFormGroup-root label {
  margin: 0 2px 0 0;
  background: #ffe9d7;
  border-radius: 5px;
  padding: 0 15px;
  text-align: center;
}

div#title_stats div:first-child {
  /* font-weight: var(--bold); */
  color: var(--primary-color) ;
  font-size: var(--font-size);
}
/* div#title_stats div {border-bottom: 1px solid #efefef; padding: 5px 0 5px;} */

div#app_title {
  width: 97% !important;
}
div#title_stats {
  background: var(--third-color);
  padding: 10px;
  border-radius: 4px;
}

div#title_median {
  margin: -15px 0 0 0;
  background: var(--third-color);
  padding: 10px;
  border-radius: 4px;
}
#tit_median {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  font-size: var(--font-size);
}
#tit_median div:first-child {
  border-right: 1px solid;
}
#tit_median b {
  color: var(--primary-color) ;
}
.regular_color {
  color: var(--title-color) !important;
}
div#title_block {
  /* font-weight: var(--bold); */
  color: var(--primary-color) ;
  font-size: var(--font-size);
}

.block_title {
  font-size: 32;
}
.detail_chart div#title_stats {
  background: transparent;
  padding: 0;
  border-radius: 0;
}
.detail_chart div#title_median {
  margin: -15px 0 0 0;
  background: transparent;
  padding: 0;
  border-radius: 0;
}
.margin_bottom {margin-bottom: 10px;}
.report_chart_new_radiogrp .active {
  color: white;
  background: var(--primary-color) ;
  padding: 0 5px;}
.report_chart_new_radiogrp span {
  border: 1px solid var(--primary-color); 
  padding: 0 5px;
  color:var(--primary-color);
}
.report_chart_new_radiogrp {margin-bottom: -10px;margin-top: 20px;display: flex;gap: 10px;}