.allouter_agentdet_sec {
  overflow: hidden;
  background: #f7f7f7;
  border-radius: 6px;
  border: 1px solid #eaeaea;
  padding: 10px 10px 0px 10px;
  margin: 0 0 10px 0;
}
.left_agentdetsec {width: 25%; float: left;}
.right_agentdetsec {width: 73%; float: right;}
.right_agentdetsec .main_agentdetsec {width: 100%;}
.right_agentdetsec .main_agentdetsec h2{font-size: var(--title-size-h2); font-weight: var(--bold); line-height: 15px; margin: 0;}
.right_agentdetsec .main_agentdetsec h3 {font-size: var(--font-size); padding: 0; color: var(--primary-color); background: none;  border:none}
.right_agentdetsec .main_agentdetsec p {font-size: var(--font-size); line-height: 17px !important; padding: 0; margin: 0;}
.right_agentdetsec .agent_description_mainsec {width: 100%;}
.right_agentdetsec .agent_description_mainsec p button {color: var(--title-color); padding: 0; font-weight: var(--bold); border-radius: 0px; text-align: center; font-size: var(--font-size); padding: 3px 3px; margin: 0;}
.right_agentdetsec .agent_description_mainsec p button i {font-size: var(--font-size);}
.right_agentdetsec .agent_description_mainsec p {font-size: var(--font-size); line-height: 18px !important; margin: 5px 0 0 0;}
.right_agentdetsec .agentfull_detsec ul li a {border: 1px solid #cecece; padding: 0px 5px;}
.right_agentdetsec .agentfull_detsec ul li i {color: var(--secondary-color); padding: 0; border-radius: 3px; margin: 5px 4px 0 1px; font-size: var(--font-size);}
.right_agentdetsec .agentfull_detsec ul li p {margin: 0; padding: 0;  line-height: 23px !important; font-size: var(--font-size-small); color: var(--title-color); text-transform: capitalize;}
.agent_fulldesc_sec{ display: none;}
.agent_fulldesc_sec p{ line-height: 16px !important;}