.inner_developersec {
  background: #f9f9f9;
  border-radius: 6px;
  margin: 0 0 10px 0;
  padding: 13px;
  border: 1px solid #f1f1f1;
}

.inner_developersec h1 {
  font-size: var(--title-size-h1);
  font-weight: var(--bold);
}

.inner_developersec p {
  font-size: var(--font-size);
  line-height: 20px;
  display: block;
}

.inner_developersec p button {
  color: var(--title-color);
  padding: 0;
  font-weight: var(--bold);
  border-radius: 0px;
  text-align: center;
  font-size: var(--font-size);
  padding: 3px 3px;
  margin: 0;
}